html {
  --background-color: #ffffff;
  --cursive-font: "Bilbo Swash Caps", cursive;
  --button-color: #F36C21;
  --dev-button-color: rgb(227, 119, 0);
  --accent-color: var(--button-color);

  background-color: var(--background-color);
}

img,
video {
  width: auto;
  height: auto;
}

.cursive {
  font-family: var(--cursive-font);
  font-weight: bold;
}

#root {
  background-color: var(--background-color);
}

#root,
.height-height {
  display: flex;
  flex-direction: column;
  flex: 1;

  height: 100%;
}

body {
  margin: 0;
  font-family: Karla, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  height: 100vh;
  height: calc(100vh - 10vh);
  height: 100svh;

  display: flex;
  flex-direction: column;
}

.cool-border-thing {
  --b: 5px; /* thickness of the border */
  --c: var(--button-color); /* color of the border */
  --w: 20px; /* width of border */

  border: var(--b) solid transparent; /* space for the border */
  --g: #0000 90deg, var(--c) 0;
  background: conic-gradient(from 90deg at top var(--b) left var(--b), var(--g))
      0 0,
    conic-gradient(from 180deg at top var(--b) right var(--b), var(--g)) 100% 0,
    conic-gradient(from 0deg at bottom var(--b) left var(--b), var(--g)) 0 100%,
    conic-gradient(from -90deg at bottom var(--b) right var(--b), var(--g)) 100%
      100%;
  background-size: var(--w) var(--w);
  background-origin: border-box;
  background-repeat: no-repeat;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@media screen and (min-width: 500px) {
  html,
  body {
    background-color: #eee !important;
  }

  #root {
    width: 80%;
    height: 80%;
    margin: 0 auto;
    margin-top: 10vh;
    box-shadow: #00000059 0px 1px 20px;
    z-index: 100;
    overflow: auto;
    border-radius: 20px;
    border: solid 20px var(--background-color);
    flex: 0 80vh;
  }
}

html,
body {
  background-color: var(--background-color);
  font-size: 16px;
  user-select: none;
}

@keyframes slidein {
  from {
    transform: translateX(20px);
  }

  /* 75% {
    font-size: 300%;
    margin-left: 25%;
    width: 150%;
  } */

  to {
    transform: translateX(-20px);
  }
}

.text-shake {
  transition: transform 0.2s;
  /* transform: translateX(11px); */

  /* animation-duration: 3s;
  animation-name: slidein; */
  animation: slidein 0.1s 4 alternate ease-in-out;
}

@keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.text-shake {
  transition: transform 0.2s;
  /* transform: translateX(11px); */

  /* animation-duration: 3s;
  animation-name: slidein; */
  animation: slidein 0.1s 4 alternate ease-in-out;
}

button {
  background-color: var(--button-color);
  color: white;
  border: none;
  cursor: pointer;

  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;

  border-radius: 20px;

  padding: 0.5rem 1rem;

  font-size: 20px;

  width: 100%;
}

button.outline {
  background-color: white;
  border: solid 1px var(--button-color);
  color: var(--button-color);
}

button:disabled {
  filter: grayscale(100%);
  opacity: 0.5;
}

button:hover:not(:disabled) {
  filter: brightness(1.5);
}

button:active:not(:disabled) {
  transform-origin: center;
  transform: scale(0.95);
}

button.selected {
  /* Bit darker background */
  /* background-color: #2e8b57; */
  filter: brightness(2);
}

.fancy-backdrop {
  background: transparent;
  color: white;
  /* backdrop-filter: blur(33px);
  background-blend-mode: overlay; */
}

/* dialog {
  z-index: 10;
  width: 100%;
  height: 100%;
  border: none;
  margin: 0;

  background-color: black;
} */

dialog {
  /* box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3); */
  border: none !important;
  /* border-radius: 10px; */
}
dialog[open] {
  display: flex;
  width: 100%;
}

dialog::backdrop {
  /* background: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.4)); */
  backdrop-filter: blur(33px);
  -webkit-backdrop-filter: blur(33px);
  /* animation: fade-in 0.2s; */
  background-blend-mode: overlay;
}

.backdrop {
  backdrop-filter: blur(33px);
  -webkit-backdrop-filter: blur(33px);
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.TrengoWidgetLauncher__iframe {
  display: none !important;
}
